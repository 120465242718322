
import React, { ComponentType } from 'react';
import { AppContext } from './App.context';

export function withAppContext<T>(Component: ComponentType<T>){
  return (props: T) => (
    <AppContext.Consumer>
      {({ 
        volusionClient,
        quantityDiscountsClient,
        }) => 
        <Component 
          volusionClient={volusionClient}
          quantityDiscountsClient={quantityDiscountsClient}
          {...props} 
        />
      }
    </AppContext.Consumer>
  )
};