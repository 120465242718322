
import React, { useState, Dispatch, SetStateAction } from 'react'; // let's also import Component
import s from './Modal.module.css'

type ModalProps = {
  setIsShowingExternal?: (x: Dispatch<SetStateAction<boolean>>) => unknown
  onClose?: () => unknown;
  text: string;
}

const Modal = ({setIsShowingExternal, text, onClose}:ModalProps) => {
  const [isShowing, setIsShowing ] = useState(false);
  setIsShowingExternal && setIsShowingExternal(setIsShowing);

  if(!isShowing){
    return (<span></span>)
  }
  return (
    <div className={s.Modal}>
      <div className={s.ModalContent}>
        {/* <div className={s.ModalHeader}>
          <h2>Modal Header</h2>
        </div> */}
        <div className={s.ModalBody}>
          <span className={s.Close} onClick={() => {
            setIsShowing(false);
            onClose && onClose();
          }}>&times;</span>
          <div>{text}</div>
        </div>
        {/* <div className={s.ModalFooter}>
          <h3>Modal Footer</h3>
        </div> */}
      </div>
    </div>
  )
}

export {Modal};