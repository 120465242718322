
import React, { Component } from 'react'; // let's also import Component
import s from './ListPricingTiers.module.css';
import { AppContext, AppContextProps } from '../Admin/App.context';
import { Table, Menu, Icon, Input, Segment } from 'semantic-ui-react';
import { withRouter, RouteComponentProps } from 'react-router';
import { GetPricingTiersResponse } from '../../modules/volusion-client/interfaces';
import { ContextOverrides } from '../AddPricingTiers/AddPricingTiers';
import { withAppContext } from '../Admin/App.provider';
// import { SingleProductPricingTier } from '../AddPricingTiers/AddPricingTiers.context';
type ListPricingTiersState = {
  data: (string | number)[][],
  currentPage: number,
  searchValue: string;
}

type ListPricingTiersProps = {
  itemsPerPage: number,
} & RouteComponentProps & AppContextProps

class ListPricingTiers extends Component<ListPricingTiersProps, ListPricingTiersState> {
  static contextType = AppContext;
  static defaultProps = {
    itemsPerPage: 20
  }
  constructor(props: ListPricingTiersProps) {
    super(props);
    this.state = {
      data : [],
      currentPage: 1,
      searchValue: '',
    }
  }

  async componentDidMount(){
    const { quantityDiscountsClient, itemsPerPage } = this.props;
    const { currentPage } = this.state;
    const isDataLoaded = await quantityDiscountsClient.load(currentPage, itemsPerPage);
    if(!isDataLoaded){
      return;
    }
    this.setState({
      data: quantityDiscountsClient.array
    });
  }

  render = () => {
    const { data, currentPage } = this.state;
    const headerRow = data[0];
    const pCodeIndex = headerRow && headerRow.findIndex(x => x.toString().toLowerCase() === 'productcode');
    return (
        <div className={s.ListPricingTiersContainer}>
          <Input placeholder='Search...' className={s.SearchBar} onChange={this.handleSearchInput}/>
          {
            data.length > 0 
            ? (
              <React.Fragment>
                <Table celled className={s.ListTable} >
                <Table.Header>
                  <Table.Row>
                    { data[0] && data[0].map( (x,l) => <Table.HeaderCell key={'header-' + l}>{x}</Table.HeaderCell> ) }
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                {
                  data.map( (x,i) => {
                    if(i === 0){
                      return null;
                    }
                    return (
                      <Table.Row onClick={ () => 
                        this.handleRowClicked(x[pCodeIndex].toString())}
                        key={'row-'+ i}
                        > 
                        { x.map( (l,oo) => <Table.Cell key={'cell--' + i + '--' + oo} >{l}</Table.Cell> ) }
                      </Table.Row>
                    )}
                  )
                }
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan={data[0] && data[0].length}>
                      <Menu floated='right' pagination>
                        <Menu.Item as='a' icon onClick={()=> this.changePage(Math.max(1,currentPage-1))}>
                          <Icon name='chevron left' />
                        </Menu.Item>
                        <Menu.Item as='a' icon onClick={()=> this.changePage(currentPage+1) }>
                          <Icon name='chevron right' />
                        </Menu.Item>
                      </Menu>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            </React.Fragment>
            )
            : <Segment>No Results</Segment>
          }
        </div>
    )
  }

  changePage = async (page: number) => {
    const { quantityDiscountsClient, itemsPerPage } = this.props;
    const { searchValue } = this.state;
    const isDataLoaded = await quantityDiscountsClient.load(page, itemsPerPage, searchValue);
    if(!isDataLoaded){
      return;
    }
    this.setState({
      data: quantityDiscountsClient.array,
      currentPage: page,
    });
  }
  handleSearchInput = async (e: { target: { value: any; }; }) => {
    const value = e.target.value;
    const { quantityDiscountsClient, itemsPerPage } = this.props;
    const isDataLoaded = await quantityDiscountsClient.load(1, itemsPerPage, value);
    this.setState({
      data: isDataLoaded ? quantityDiscountsClient.array : [],
      searchValue: value,
      currentPage: 1,
    });
  }

  private async handleRowClicked(productCode:string) {
    const { volusionClient } = this.props;
    let pricingTierData: GetPricingTiersResponse[];
    try {
      pricingTierData = await volusionClient.getPricingTiers(productCode);
      const contextOverrides: ContextOverrides = {
        selectedProduct: {
          ProductCode: productCode,
          ProductID: '000',
          ProductName: pricingTierData[0].ProductName,
          ProductPrice: pricingTierData[0].ProductPrice,
        },
        pricingTiers: pricingTierData.map(x => {
          const pTierQuantity = x.OptionsDesc.split('---')[1];
          if (!pTierQuantity) {
            return undefined;
          }
          return {
            quantity: parseFloat(pTierQuantity),
            price: parseFloat(x.Price)
          };
        }).filter(notEmpty),
      }
      this.props.history.push('/edit/' + productCode, {
        contextOverrides
      });
    } catch(e) {
      console.error(e);
    }
  }

  private setUpPagination(data: (string | number)[][], headerRow: (string | number)[], searchValue: string, currentPage: number, itemsPerPage: number) {
    let currentData = [...data];
    currentData.shift();
    const productCodeIndex = headerRow.findIndex(x => typeof x === 'string' && x.toLowerCase() === 'productcode');
    currentData = currentData.filter(x => {
      if (!searchValue) {
        return true;
      }
      const pCode = x[productCodeIndex];
      if (typeof pCode !== 'string') {
        return false;
      }
      return pCode.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0;
    });
    const totalDataInThisQuery = currentData.length;
    currentData = currentData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);
    currentData = [headerRow].concat(currentData);
    const totalPages = Math.floor((totalDataInThisQuery) / itemsPerPage);
    const page = pagination(currentPage, totalPages);
    return { currentData, page, totalPages };
  }
}

function pagination(c:number, m:number) {
  var current = c,
      last = m,
      delta = 2,
      left = current - delta,
      right = current + delta + 1,
      range = [],
      rangeWithDots = [],
      l;

  for (let i = 1; i <= last; i++) {
      if (i === 1 || i === last || (i >= left && i < right)) {
          range.push(i);
      }
  }

  for (let i of range) {
      if (l) {
          if (i - l === 2) {
              rangeWithDots.push(l + 1);
          } else if (i - l !== 1) {
              rangeWithDots.push('...');
          }
      }
      rangeWithDots.push(i);
      l = i;
  }

  return rangeWithDots;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export default withRouter(withAppContext(ListPricingTiers));