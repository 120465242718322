import VolusionClient from '../volusion-client/volusion-client';
import { notEmpty } from '../../components/ListPricingTiers/ListPricingTiers';
import { VolusionActions, CartDetail } from '../volusion-actions/volusion-actions';
import { GetCartResponse } from '../volusion-client/interfaces';
import { getSmallestTierQuantity } from '../../components/CategoryPricingTierDisplay/CategoryPricingTierDisplay';

export interface CartControllerOptions {
  volusionClient: VolusionClient;
  volusionActions: VolusionActions;
}

export default class CartController {
  private readonly volusionClient: VolusionClient;
  private readonly volusionActions: VolusionActions;
  private cartData: GetCartResponse | undefined;

  private readonly quantityDiscountOptionCategoryID =
    process.env.REACT_APP_QUANTITY_DISCOUNT_OPTION_CATEGORY || '45';
  constructor(options?: CartControllerOptions) {
    this.volusionClient =
      (options && options.volusionClient) || new VolusionClient();
    this.volusionActions =
      (options && options.volusionActions) || new VolusionActions(process.env.REACT_APP_STORE_URL || '');
  }

  async hideCorrectOptionsOnCartPage(){
    if(window.location.href.toLowerCase().indexOf('shoppingcart.asp') === -1){
      return;
    }
    try {
      const cartData = await this.getCartData();
      
      const optionSelectLinks = document.querySelectorAll('a.cart-item-name + a');
      [...optionSelectLinks].forEach( (ele, i) => {
        ele.setAttribute('hidden','')
        const itemHasPricingTierOption = cartData.Products[i].Options.find(x => x[0].indexOf('Quantity Discount') >= 0 );
        if(!itemHasPricingTierOption){
          return;
        }
        ele.setAttribute('hidden','');
      })

    } catch (e) {
      console.error(e);
    }
  }

  async updatePricing() {
    const checkoutButton = document.querySelector('[name="btn_checkout_guest"]');
    if(checkoutButton){
      (checkoutButton as HTMLElement).style.visibility = 'hidden'
    }
    const cartData = await this.getCartData();
    const cartDataWithPricingTiersPromises = cartData.Products.map(async x => {
      const pricingTiers = await this.volusionClient.getPricingTiers(
        x.ProductCode,
      );
      if (!(pricingTiers && pricingTiers.length)) {
        return undefined;
      }
      if(parseFloat(pricingTiers[0].SalePrice || '0') > 0 ) {
        return;
      }

      const productPriceTokens = x.ProductPrice.match(/^(?:&pound;|£)(.*?)$/)
      const productPrice = productPriceTokens && productPriceTokens[1];
      if(!productPrice){
        return;
      }
      const unitPrice = pricingTiers[0].ProductPrice;
      return {
        ...x,
        ProductPrice: productPrice,
        UnitPrice: unitPrice,
        pricingTiers,
      };
    });
    let cartDataWithPricingTierResponses = await Promise.all(
      cartDataWithPricingTiersPromises,
    );
    const cartDataWithPricingTiers = cartDataWithPricingTierResponses.filter(
      notEmpty,
    );
    const productsToUpdate = cartDataWithPricingTiers.map(productToUpdate => {
      let highestPricingTier = null;
      
      const minimumQuantityToAddToOrder = getSmallestTierQuantity(productToUpdate.pricingTiers, productToUpdate.ProductCode, cartData);
      // productToUpdate.Quantity = String(Math.max(minimumQuantity, parseInt(productToUpdate.Quantity)));
      const quantityThatNeedsToBeInCart = Math.min(
        parseInt(productToUpdate.Quantity) + minimumQuantityToAddToOrder, 
        (parseInt(productToUpdate.pricingTiers[0].StockStatus) || Number.MAX_VALUE) // Accounts for the case when the tier is larger than the stock available.
      );

      for (let i = 0; i < productToUpdate.pricingTiers.length; i++) {
        const currentData = productToUpdate.pricingTiers[i];
        const quantity = currentData.OptionsDesc.split('---')[1];
        if(!quantity){
          continue;
        }
        if (quantityThatNeedsToBeInCart >= parseInt(quantity)) {
          highestPricingTier = currentData;
        }
      }
      if(!highestPricingTier){
        return undefined;
      }
      const productValueAtThisTier = parseFloat((parseInt(productToUpdate.Quantity) * parseFloat( highestPricingTier.Price)).toFixed(2));
      const cartPrice = parseFloat(productToUpdate.ProductPrice);
      if(
        !isNaN(productValueAtThisTier) && 
        !isNaN(cartPrice) && 
        productValueAtThisTier === cartPrice && 
        minimumQuantityToAddToOrder === 0
      ){
        return undefined;
      }
      return {
        removeIndex: productToUpdate.ProductIndex,
        addToCart: {
          productCode: productToUpdate.ProductCode,
          quantity: String(quantityThatNeedsToBeInCart),
          options: [{
            optionCategoryDisplayType: 'DROPDOWN',
            optionCategoryID: this.quantityDiscountOptionCategoryID,
            optionID: highestPricingTier.ID,
          }]
        } as CartDetail
      }
    }).filter(notEmpty);

    if(checkoutButton){
      (checkoutButton as HTMLElement).style.visibility = 'visible'
    }

    if(!productsToUpdate.length){
      return;
    }

    await productsToUpdate
      .map( x => parseInt(x.removeIndex) )
      .sort((a,b) => b-a)
      .reduce( async (previousPromise, index) => {
        try {
          await previousPromise;
        } catch (e){
          console.error(e);
        }
         return this.volusionActions.removeFromCart(index)
      }, Promise.resolve(''))
    
    const addTheseToCart = productsToUpdate.map( x => x.addToCart )
    await this.volusionActions.postToCart(addTheseToCart);
    this.volusionActions.navigateToCart();
  }

  private async getCartData() {
    if(this.cartData){
      return this.cartData;
    }
    return this.volusionActions.getCart();
  }
}
