import { VolusionClientOptions, GetCustomerResponse, GetProductResponse, GetPricingTiersResponse, IsProductInWishListResponse, GetPricingTiersCountResponse } from './interfaces';
import { Row } from 'neat-csv';
import { Stream } from 'stream';

export default class VolusionClient {
  private basePath: string;
  private searchProductsApi: string;
  private searchCustomersApi: string;
  private putPricingTiersApi: string;
  private getProductPricingTiersApi: string;
  private getPricingTiersApi: string;
  private isProductInWishlistApi: string;
  private getPricingTiersCountApi: string;
  private isLoggedInAPI: string;

  constructor(options?: VolusionClientOptions) {
    this.basePath = ((options && options.storeURL) || '')  + ((options && options.basePath) || '/v/endpoints/');
    this.searchProductsApi = (options && options.searchProductsApi) || 'search-products.asp';
    this.searchCustomersApi = (options && options.searchCustomersApi) || 'search-customer.asp'
    this.putPricingTiersApi = (options && options.putPricingTiersApi) || 'put-pricing-tiers.asp'
    this.getProductPricingTiersApi = (options && options.getProductPricingTiersApi) || 'get-product-pricing-tiers.asp'
    this.getPricingTiersApi = (options && options.getPricingTiersApi) || 'get-pricing-tiers.asp'
    this.getPricingTiersCountApi = (options && options.getPricingTiersCountApi) || 'get-pricing-tiers-count.asp'
    this.isProductInWishlistApi = (options && options.isProductInWishlistApi) || 'is-product-in-wishlist.asp'
    this.isLoggedInAPI = (options && options.isLoggedInAPI) || '/ajax_receiver.asp?system=isloggedin';

    this.searchCustomers = this.searchCustomers.bind(this);
    this.searchProducts = this.searchProducts.bind(this);
    this.xmlToJson = this.xmlToJson.bind(this);
    this.extractData = this.extractData.bind(this);
    this.addFieldToObject = this.addFieldToObject.bind(this);
  }


  async getLoggedInStatus() {
    return fetch(`${this.isLoggedInAPI}`, {
      method: 'POST',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    })
      .then(res => res.json())
      .then(data => {
        return data.IsLoggedIn;
      });
  }

  async isProductInWishlist(productCode: string, customerID: string | number): Promise<IsProductInWishListResponse[]> {
    return await fetch(
      `${this.basePath}${
        this.isProductInWishlistApi
      }?product_code=${encodeURIComponent(
        productCode,
      )}&customer_id=${encodeURIComponent(customerID)}&_=${Date.now()}`,
    )
      .then(res => res.text())
      .then(text => {
        const domParser = new DOMParser();
        return domParser.parseFromString(text, 'text/xml');
      })
      .then(xml => {
        if (!xml) {
          throw new Error('Unable to parse text as xml');
        }
        return this.extractData(xml.getElementsByTagName('WishLists'));
      })
      .then(
        htmlCollection => [...htmlCollection] as IsProductInWishListResponse[],
      );
  }

  async getPricingTierCount(): Promise<GetPricingTiersCountResponse[]> {
    return await fetch(
      `${this.basePath}${this.getPricingTiersCountApi}?_=${Date.now()}`,
    )
      .then(res => res.text())
      .then(text => {
        const domParser = new DOMParser();
        return domParser.parseFromString(text, 'text/xml');
      })
      .then(xml => {
        if (!xml) {
          throw new Error('Unable to parse text as xml');
        }
        return this.extractData(xml.getElementsByTagName('Options'));
      })
      .then(htmlCollection => [...htmlCollection] as GetPricingTiersCountResponse[] )
  }

  async getAllPricingTiers(page=1, pageSize=20, product_code: string=''): Promise<GetPricingTiersResponse[]> {
    return await fetch(
      `${this.basePath}${this.getPricingTiersApi}?_=${Date.now()}&page=${page}&page_size=${pageSize}&product_code=${product_code}`,
    )
      .then(res => res.text())
      .then(text => {
        const domParser = new DOMParser();
        return domParser.parseFromString(text, 'text/xml');
      })
      .then(xml => {
        if (!xml) {
          throw new Error('Unable to parse text as xml');
        }
        return this.extractData(xml.getElementsByTagName('Options'));
      })
      .then(htmlCollection => [...htmlCollection] as GetPricingTiersResponse[] )
  }

  async getPricingTiers(productCode: string): Promise<GetPricingTiersResponse[]> {
    return await fetch(
      `${this.basePath}${this.getProductPricingTiersApi}?product_code=${productCode}&_=${Date.now()}`,
    )
      .then(res => res.text())
      .then(text => {
        const domParser = new DOMParser();
        return domParser.parseFromString(text, 'text/xml');
      })
      .then(xml => {
        if (!xml) {
          throw new Error('Unable to parse text as xml');
        }
        return this.extractData(xml.getElementsByTagName('Options'));
      })
      .then(htmlCollection => [...htmlCollection] as GetPricingTiersResponse[] )
  }

  async addPricingTiersToProducts(pricingTiers: Row[], callback: (a: unknown, b: Stream) => unknown){
    const filtered = pricingTiers
    .filter( x => {
      const keys = Object.keys(x);
      const foundProductCode = keys.indexOf('productcode') >= 0;
      const hasPricingTierOrMinimumQuantity = keys.length >= 2;
      return hasPricingTierOrMinimumQuantity && foundProductCode;
    });

    let i = 0;
    let total = filtered.length;
    const dataStream = new Stream();
    callback(null, dataStream);
    
    await filtered.reduce( async (previousPromise, currentProduct) => {
      let tiers = '';
      Object.keys(currentProduct)
      .filter(quantity => quantity !== 'productcode' && currentProduct[quantity])
      .forEach((quantity,j) => {
        tiers += `
          <Tier>  
            <Quantity>${quantity}</Quantity>
            <Price>${currentProduct[quantity]}</Price>
          </Tier>
        `;
      });
      let xml = `
      <PricingTier>
        <ProductCode>${currentProduct.productcode}</ProductCode>
        ${tiers}
      </PricingTier>`;
      try {
        await previousPromise;
      } catch (e){
        console.error(e);
      }
      const percent2 = ((i/total) * 100);
      i+=1;
      dataStream.emit('data', percent2);
      return fetch(`${this.basePath}${this.putPricingTiersApi}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'text/xml',
          },
          body: xml,
        })
        .then(res => res.text())
    }, Promise.resolve(''));
    dataStream.emit('end', 100);
  }
  
  async searchCustomers(search: string): Promise<GetCustomerResponse[]>{

    return await fetch(
      `${this.basePath}${this.searchCustomersApi}?search=${search}`,
    )
      .then(res => res.text())
      .then(text => {
        const domParser = new DOMParser();
        return domParser.parseFromString(text, 'text/xml');
      })
      .then(xml => {
        if (!xml) {
          throw new Error('Unable to parse text as xml');
        }
        return this.extractData(xml.getElementsByTagName('Customers'));
      })
      .then(htmlCollection => [...htmlCollection] as GetCustomerResponse[] )
  };
  

  async searchProducts(search: string): Promise<GetProductResponse[]>{
    if (!search) {
      console.warn('Missing required parameter: search');
      throw new Error('Missing required parameter: search');
    }

    return await fetch(
      `${this.basePath}${this.searchProductsApi}?search=${search}`,
    )
      .then(res => res.text())
      .then(text => {
        const domParser = new DOMParser();
        return domParser.parseFromString(text, 'text/xml');
      })
      .then(xml => {
        if (!xml) {
          throw new Error('Unable to parse text as xml');
        }
        return this.extractData(xml.getElementsByTagName('Products'));
      })
      .then(htmlCollection => [...htmlCollection] as GetProductResponse[])
  };

  xmlToJson(xmlElement: Element | ChildNode) {
    let data = {};
    xmlElement &&
      xmlElement.childNodes &&
      [...xmlElement.childNodes].forEach(node => {
        if (node.nodeName && !node.nodeValue) {
          if (node.childNodes.length > 1) {
            this.addFieldToObject(data, node, this.xmlToJson(node));
          } else {
            this.addFieldToObject(data, node, node.textContent);
          }
        }
      });
    return data;
  }
  /**
   * Converts array of xml tags to htmlCollection
   * @param {Array} elements all tags and info from the returned xml
   * @returns {Object} htmlCollection from returned xml
   */
  extractData(elements:HTMLCollectionOf<Element>) {
    let extractedData = (elements && [...elements].map(this.xmlToJson)) || [];
    return extractedData;
  }
  
  addFieldToObject(data: any, node: ChildNode, value: any) {
    if (!data[node.nodeName]) {
      data[node.nodeName] = value;
    } else if (Array.isArray(data[node.nodeName])) {
      data[node.nodeName].push(value);
    } else {
      const oldVal = data[node.nodeName];
      data[node.nodeName] = [oldVal, value];
    }
  }
}



// function chunk<T>(array: T[], size: number) {
//   const chunked_arr = [];
//   for (let i = 0; i < array.length; i++) {
//     const last = chunked_arr[chunked_arr.length - 1];
//     if (!last || last.length === size) {
//       chunked_arr.push([array[i]]);
//     } else {
//       last.push(array[i]);
//     }
//   }
//   return chunked_arr;
// }
