import { GetCartResponse } from '../volusion-client/interfaces';

export class VolusionActions {
  private baseUrl: string;
  constructor(baseUrl: string){
    this.baseUrl = baseUrl;
  }

  navigateToCart(){
    window.location.assign(
      `${this.baseUrl}/shoppingcart.asp`
    )
  }

  removeFromCart(index: string | number){
    // returns promise
    return fetch(`${this.baseUrl}/shoppingcart.asp?remove=${index}`, {
      method: 'GET',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: undefined,
    }).then(r => r.text());
  }

  /**
   * Calls getUrl() and postToCart(). Directs whether the cart is added to synchronously
   *  or asynchronously. Redirects to card if sync - returns promise if async
   * @param {Object} [orderDetails=[]] Elements with keys for productCode and quantity
   * @param {Boolean} [sync=true] True is sync GET | False is async POST fetch
   */

  addToCart(orderDetails:CartDetail[]) {
    return document.location.assign(
      this.addToCartURL(orderDetails),
    );
  }
  addToCartURL(orderDetails: CartDetail[]): string {
    return `${this.baseUrl}/shoppingcart.asp?${this.getUrl(orderDetails)}`;
  }

  async getCart(): Promise<GetCartResponse> {
    return fetch(`/ajaxcart.asp?_=${Date.now()}`, {
      method: 'GET',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    })
      .then(res => res.json())
      .then(d => d as GetCartResponse)
      .then(d => {
        this.updateCartTotal(d);
        this.updateProductCartCountTotals(d);
        return d;
      });
  }

  private updateCartTotal(d: GetCartResponse) {
    const cartTotal = d.Totals && d.Totals[0] && d.Totals[0].CartTotal;
    const elesToChange = document.querySelectorAll('[data-v-observable="cart-total"]');
    if (elesToChange && elesToChange.length) {
      elesToChange.forEach(ele => {
        (ele as HTMLElement).innerHTML = cartTotal;
      });
    }
  }

  private updateProductCartCountTotals(d: GetCartResponse) {
    const elesToChange = document.querySelectorAll('[data-v-observable="product-count"]');
    if(!(elesToChange && elesToChange.length && d.Products.length)){
      return;
    }
    elesToChange.forEach( ele => {
      const productCode = ele.getAttribute('data-product-code');
      if(!productCode){
        return;
      }
      const foundItemInCart = d.Products.find(x => x.ProductCode.toUpperCase() === productCode.toUpperCase());
      let cartQuantity = 0;
      if(foundItemInCart) {
        cartQuantity = parseInt(foundItemInCart.Quantity || '0') || 0;
      }
      (ele as HTMLElement).innerHTML = String(cartQuantity);
      const eleParent = (ele as HTMLElement).parentElement;
      if(eleParent){
        eleParent.style.color = cartQuantity === 0 ? 'black' : 'green';
      }
    });
  }

  /**
   * Calls getUrl, Submits post req for products to the cart, and Redirects to cart.
   * @param {String} [baseUrl=''] Base url for the website
   * @param {Object} [payload=[]] Elements with keys for productCode and quantity
   */

  async postToCart(payload:CartDetail[]) {
    // returns promise
    const response = await fetch(`${this.baseUrl}/shoppingcart.asp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: this.getUrl(payload),
    })
    .then( r => r.text())
    .then(async r => {
      return await this.getCart();
    });
    return response;
  }

  /**
   * Creates query string for url or payload
   * @param {Object} [products=[]] Elements with keys for productCode and quantity
   */

  getUrl(products:CartDetail[]) {
    // eslint-disable-next-line no-debugger
    debugger;
    let url = '';
    products &&
      products.forEach((item, i) => {
        if (i > 0) {
          url += '&';
        }

        url += `ProductCode=${item.productCode.toUpperCase()}&Qty.${item.productCode.toUpperCase()}=${encodeURIComponent(
          item.quantity,
        )}`;

        /**************************************
         * add in the logic here for options
         **************************************/
        if (item.options) {
          item.options.forEach(option => {
            if (option.optionCategoryDisplayType === 'DROPDOWN') {
              url += `&SELECT___${encodeURIComponent(
                item.productCode.toUpperCase(),
              )}___${encodeURIComponent(
                option.optionCategoryID,
              )}=${encodeURIComponent(option.optionID)}`.toUpperCase();
            } else if (option.optionCategoryDisplayType === 'TEXTBOX') {
              url += `&TEXTBOX___${encodeURIComponent(
                option.optionID,
              )}___${encodeURIComponent(
                item.productCode,
              )}___${encodeURIComponent(
                option.optionCategoryID,
              )}=${encodeURIComponent(
                item.productCode || '',
              )}`.toUpperCase();
            }
          });
        }
      });
    return url;
  }

  async addToEmailWhenBackInStockList(productCode: string, emailAddress: string){
    return fetch(`${this.baseUrl}/Email_Me_When_Back_In_Stock.asp?ProductCode=${encodeURIComponent(productCode)}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      },
      body: `EmailAddress=${ encodeURIComponent(emailAddress)}&reCapFix=true&btnSubmit=Submit`,
    }).then(r => r.text());
  }


  async getLoggedInStatus() {
    return fetch(`${this.baseUrl}/ajax_receiver.asp?system=isloggedin&_=${Date.now()}`, {
      method: 'POST',
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json',
      },
      body: null,
    })
      .then(res => res.json())
      .then(data => {
        return data.IsLoggedIn === true;
      });

  }
  async getCustomerEmail(): Promise<CurrentUserResponse> {
      return fetch(`${this.baseUrl}/api/v1/users/current`, {
        method: 'GET',
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json',
        },
        body: null,
      })
        .then(res => res.json())
        .then(d => d as CurrentUserResponse);
    }

  addToWishlistURL(productCode: string) {
    return `/WishList.asp?WL_Action=add&ProductCode=${encodeURIComponent(
      productCode,
    )}`
  }
}

export type CurrentUserResponse = {
  exceptions: never[ ],
  warnings: never[ ],
  errors: never[ ],
  data: {
  isAnonymous: null,
  firstName: string,
  id: number,
  email: string,
  storeCreditAvailable: number,
  lastName: string,
  }
}

export type AddOption = {
  optionCategoryDisplayType: 'DROPDOWN' | 'TEXTBOX', 
  optionID: string, 
  optionCategoryID: string
}

export type CartDetail = { 
  productCode: string, 
  quantity: string,
  options?: AddOption[],
}