import CartController from '../modules/cart-controller/cart-controller';

export default (async () => {
  const cartController = new CartController();

  if (  
    window.location.href.toLowerCase().indexOf('-p/') >= 0 || 
    window.location.href.toLowerCase().indexOf('_p/') >= 0 || 
    window.location.href.toLowerCase().indexOf('productdetails') >= 0 
  ) {
    await import('./product');
  }
  else if (  
    window.location.href.toLowerCase().indexOf('-s/') >= 0 || 
    window.location.href.toLowerCase().indexOf('_s/') >= 0 ||
    window.location.href.toLowerCase().indexOf('searchresults.asp') >= 0
  ) {
    await import('./category');
  } else if (window.location.href.toLowerCase().indexOf('shoppingcart.asp') >= 0){
    cartController.hideCorrectOptionsOnCartPage();
  } else if (
    document.body && 
    document.body.classList && 
    document.body.classList.contains('admin-interface')) {
      await import('./admin');
  }

  try {
    await cartController.updatePricing();
  } catch (e) {
    console.error(e);
  }

  return true;
})();