import React from 'react';

/**
 * Put all imported modules here.
 */

import VolusionClient from '../../modules/volusion-client/volusion-client';
import QuantityDiscountsClient from '../../modules/quantity-discounts-client/quantity-discounts-client';
export type AppContextProps = { 
  volusionClient: VolusionClient,
  quantityDiscountsClient: QuantityDiscountsClient,
};

export const AppContext = React.createContext<Partial<AppContextProps>>({});
