import React, { useState, Dispatch, SetStateAction } from 'react';
import { GetPricingTiersResponse, GetCartResponseProduct, GetCartResponse } from '../../modules/volusion-client/interfaces';
import { VolusionActions, CartDetail } from '../../modules/volusion-actions/volusion-actions';

import s from './CategoryPricingTierDisplay.module.css'
import VolusionClient from '../../modules/volusion-client/volusion-client';
import { Modal } from '../Modal';
import { QuantityDiscountData } from '../../modules/quantity-discount-product-display/quantity-discount-product-display';

type CategoryPricingTierDisplayProps = {
  titleHTML: () => string;
  availabilityHTML: () => string;
  productCode: string;
  pricingTierData: GetPricingTiersResponse[];
  isInStock: boolean;
  customerEmail: string,
  volusionActions: VolusionActions, 
  volusionClient: VolusionClient,
  initialCartData: GetCartResponse,
  initialQuantity: number,
}

const buttonClasses = [s.BasketButton, "vCSS_input_addtocart","btn btn-primary", "btn-lg","btn_addtocart"].join(' ');

export const getSmallestTierQuantity = (
  pricingTierData: GetPricingTiersResponse[] | QuantityDiscountData[], 
  productCode: string,
  initialCartData?: GetCartResponse, 
) => {
  const foundProduct = initialCartData && initialCartData.Products.find( x => x.ProductCode.toUpperCase() === productCode.toUpperCase());
  const initialQuantity = foundProduct ? parseInt(foundProduct.Quantity) : 0;
  let smallestTierQuantity = Number.MAX_SAFE_INTEGER;
  for(let i=0; i < pricingTierData.length; i++ ){
    const currentData = pricingTierData[i];
    let tierQuantity = -1;
    if(currentData['OptionsDesc']){
      tierQuantity = parseInt((currentData as GetPricingTiersResponse).OptionsDesc.split('---')[1].trim());
    } else if (currentData['quantity'] !== undefined) {
      tierQuantity = parseInt((currentData as QuantityDiscountData).quantity);
    }
    if(tierQuantity === -1) {
      continue;
    }

    tierQuantity = Math.max(0, tierQuantity - initialQuantity);
    if(!currentData) {
      continue
    };
    if(tierQuantity < smallestTierQuantity ){
      smallestTierQuantity = tierQuantity;
    }
  }
  if(smallestTierQuantity === Number.MAX_SAFE_INTEGER){
    smallestTierQuantity = 0;
  }
  return smallestTierQuantity;
}


const CategoryPricingTierDisplay: React.FC<CategoryPricingTierDisplayProps> = ({ 
  titleHTML, 
  availabilityHTML, 
  productCode, 
  pricingTierData, 
  isInStock, 
  customerEmail, 
  volusionActions, 
  volusionClient,
  initialCartData,
  initialQuantity,
}) => {  


  const smallestTierQuantity = getSmallestTierQuantity(pricingTierData, productCode, initialCartData);
  const [quantity, setQuantity] = useState(smallestTierQuantity);
  let setShowModal: Dispatch<SetStateAction<boolean>>;

  let setUpExternalShowModal = (x: Dispatch<SetStateAction<boolean>>) => {
    setShowModal = x;
  }

  const handleAddToCart = async (
      volusionClient: VolusionClient, 
      pricingTierData: GetPricingTiersResponse[], 
      volusionActions: VolusionActions,
      quantity: number,
      productCode: string,
  ) => { 
    let previousItemInCart: GetCartResponseProduct | undefined;
    let cartData: GetCartResponse | undefined;
    try {
      cartData = await volusionActions.getCart();
      previousItemInCart = cartData.Products.find( x => x.ProductCode.toUpperCase() ===  productCode.toUpperCase() ) as GetCartResponseProduct;
    } catch (e){
      console.error(e);
    }
    if(!cartData){
      return;
    }
    const smallestTierQuantity = getSmallestTierQuantity(pricingTierData, productCode, cartData)
    const additionalQuantityAlreadyInCart = previousItemInCart ? parseInt(previousItemInCart.Quantity) : 0;
    const combinedQuantity = Math.max(quantity + additionalQuantityAlreadyInCart, smallestTierQuantity, 1);

    let highestPricingTier = null;
    for( let i=0; i < pricingTierData.length; i++ ){
      const currentData = pricingTierData[i];
      const tierQuantity = currentData.OptionsDesc.split('---')[1].trim();
      if(!currentData) {continue};
      if(combinedQuantity >= parseInt(tierQuantity) ){
        highestPricingTier = currentData;
      }
    }

    const addToCartData = {
      productCode,
      quantity: String(combinedQuantity),
    } as CartDetail;

    if(highestPricingTier){
      addToCartData.options = [
        {
          optionCategoryDisplayType: 'DROPDOWN',
          optionID: highestPricingTier.ID,
          optionCategoryID: highestPricingTier.OptionCatID
        }
      ]
    }

    if(previousItemInCart){
      try {
        await volusionActions.removeFromCart(previousItemInCart.ProductIndex);
      } catch (e){
        console.error(e);
      }
    }

    await volusionActions.postToCart([addToCartData])
    window.DisplayCartPopupBegin && window.DisplayCartPopupBegin();
    setQuantity(Math.max(quantity, smallestTierQuantity));
  }

  const handleQuantityChange = (
    initialQuantity: number,
    quantityStr: string, 
    initialCartData: GetCartResponse, 
    pricingTierData: GetPricingTiersResponse[], 
    productCode: string
  ) => {
    const quantity = parseInt(quantityStr);
    // const smallestTierQuantity = getSmallestTierQuantity(pricingTierData, productCode, initialCartData);
    // setQuantity(Math.max(quantity, smallestTierQuantity));
    setQuantity(Math.max(quantity, 0));
  }

  return (
  <div className={s.PricingTierOuterContainer}>
    <div className={s.ProductTitle} dangerouslySetInnerHTML={{__html: titleHTML() }}>
    </div>
    <div className={s.PricingTierContainer}>
      <div className={s.TableContainer}>
        <table>
          <tr>
            <td>QTY</td>
            <td>£ / Item</td>
          </tr>
          { pricingTierData.map( data => {
            const tierQuantity = data.OptionsDesc.split('---')[1].trim();
            return (
              <tr>
                <td>{tierQuantity}+</td>
                <td>£{ data.Price }</td>
              </tr>
            )
        })}
        </table>
      </div>
      <div className={s.AvailabilityContainer}>
        <div className={s.Availability} dangerouslySetInnerHTML={{__html: availabilityHTML() }}></div>
        <div className={s.Qty}>
          <span 
            className={s.QtyTitle}>Qty:</span>
          <input 
            type="number"
            min="0"
            className={s.QtyValue} 
            value={quantity}
            defaultValue={String(smallestTierQuantity)}
            placeholder='' 
            onChange={(e) => 
              handleQuantityChange(quantity, e.target.value, initialCartData, pricingTierData, productCode)
            }
          />  
          <span className={s.InitialQuantity} style={{ color: initialQuantity === 0 ? 'black' : 'green' }}>
            <span data-v-observable="product-count" data-product-code={productCode}>{initialQuantity}</span> in basket
          </span>
        </div>
      </div>
      {
        isInStock 
        ? <button
            className={buttonClasses}
            type="button"
            onClick={ () => handleAddToCart(volusionClient, pricingTierData, volusionActions, quantity, productCode)}
          >
            Add To Basket
          </button> 
        : <button
            className={buttonClasses}
            type="button"
            onClick={ async () => {
              await volusionActions.addToEmailWhenBackInStockList(productCode, customerEmail);
              setShowModal(true);
            }}
          >
            Email <span className={s.MeTextInButton}>Me</span> When Back<span className={s.MeTextInButton}> In Stock</span>
          </button> 
      }
      <Modal 
        setIsShowingExternal={setUpExternalShowModal}
        text={'You will be notified when this item is back in stock.'} 
        onClose={() => {
          setShowModal(false);
        }}
      />
    </div>
  </div>);
}

export default CategoryPricingTierDisplay;
